<ng-container *ngIf="isDonationEnabled">
  <mzima-client-button
    (buttonClick)="showDonation()"
    *ngIf="!donationService.isMonetizationStarted"
  >
    <span>{{ 'app.donate' | translate }}</span>
    <mat-icon icon svgIcon="heart"></mat-icon>
  </mzima-client-button>

  <mzima-client-button
    class="donation-amount"
    (buttonClick)="showDonation()"
    *ngIf="donationService.isMonetizationStarted"
  >
    <mat-icon prefix class="donation-amount__icon" svgIcon="coins"></mat-icon>
    <span>{{ donationInfo.formattedAmount }}</span>
    <mat-icon icon class="donation-amount__icon" svgIcon="info-circle"></mat-icon>
  </mzima-client-button>
</ng-container>
