<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<strong mat-dialog-title>
  {{ (!!data.search ? 'set.update_saved_filter' : 'set.create_saved_filter') | translate }}
</strong>

<form novalidate [formGroup]="form" (ngSubmit)="formSubmit()">
  <div class="form-row">
    <mat-label>{{ 'app.name' | translate }} *</mat-label>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Name your Saved Search..." formControlName="name" />
    </mat-form-field>
    <ng-container *ngFor="let err of formErrors">
      <ng-container *ngIf="err?.field === 'name'">
        <mat-error *ngFor="let msg of err?.error_messages">
          {{ msg | translate }}
        </mat-error>
      </ng-container>
    </ng-container>
  </div>

  <div class="form-row">
    <mat-label>{{ 'app.description' | translate }}</mat-label>
    <mat-form-field appearance="outline" disabled>
      <input matInput formControlName="description" />
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-label>{{ 'app.who_can_see_this' | translate }}</mat-label>
    <app-group-checkbox-select [data]="roleOptions" formControlName="visible_to">
    </app-group-checkbox-select>
    <div class="radio-buttons" *ngIf="form.value.parent">
      <p class="radio-buttons__info">
        <mat-icon>group</mat-icon>
        {{ 'category.child_permissions' | translate }}
      </p>
    </div>
  </div>

  <!-- <div class="form-row">
    <mat-slide-toggle formControlName="featured">
      {{ 'saved_search.featured' | translate }}
      <span class="tooltip-icon" [matTooltip]="'saved_search.featured_tooltip' | translate">?</span>
    </mat-slide-toggle>
  </div> -->

  <div class="form-row">
    <mat-label>{{ 'set.default_viewing_mode' | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="defaultViewingMode">
        <mat-option value="map">
          {{ 'views.map' | translate }}
        </mat-option>
        <mat-option value="data">
          {{ 'views.data' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-row">
    <div class="toggle">
      <mat-slide-toggle formControlName="is_notifications_enabled">
        {{ 'nav.receive_notifications' | translate }}
      </mat-slide-toggle>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <mzima-client-button *ngIf="!!data.search" color="danger" (buttonClick)="deleteSavedfilter()">
      {{ 'app.delete' | translate }}
    </mzima-client-button>
    <div class="button-group">
      <mzima-client-button fill="outline" color="secondary" (buttonClick)="cancel()">
        {{ 'app.cancel' | translate }}
      </mzima-client-button>
      <mzima-client-button
        [disabled]="form.invalid || form.disabled"
        id="apply-saved-filters"
        type="submit"
      >
        {{ 'app.apply_filters' | translate }}
      </mzima-client-button>
    </div>
  </div>
</form>
