<app-onboarding
  *ngIf="!isOnboardingDone && isDesktop && checkAllowedAccessToSite() && showOnboarding"
></app-onboarding>

<mat-sidenav-container
  [dir]="isRTL ? 'rtl' : 'ltr'"
  class="main"
  [ngClass]="{ 'main--inner': !isDesktop && isInnerPage }"
>
  <mat-sidenav opened mode="side" disableClose class="sidebar" *ngIf="isDesktop">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-toolbar
      [languages]="languages"
      [selectedLanguage]="selectedLanguage$ | async"
    ></app-toolbar>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
    <div class="mobile-page-nav" *ngIf="!isDesktop && !isInnerPage">
      <app-submit-post-button
        class="mobile-page-nav__add-post-btn"
        [isRound]="true"
        [hasIconOnly]="true"
        [hasText]="false"
        *ngIf="checkAllowedAccessToSite()"
      >
      </app-submit-post-button>
      <app-menu-list-links></app-menu-list-links>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-cookies-notification></app-cookies-notification>

<app-spinner fullscreen="true" *ngIf="isShowLoader"></app-spinner>
