<ng-container *ngFor="let step of onboardingSteps; let i = index">
  <tour-step
    [order]="i"
    *ngIf="!step.hidden"
    class="onboarding-step selector-{{ step.selector }} order-{{ i }}"
    [selector]="step.selector"
    [isFooterVisible]="false"
    [position]="step.position ?? ''"
    [ngClass]="{
      'filters-visible': isFiltersVisible,
      'onboarding-step--hidden': isHidden
    }"
  >
    <div class="onboarding-step__head">
      <h2>{{ step.title }}</h2>
      <mat-icon
        class="onboarding-step__head__icon"
        *ngIf="step.icon"
        [svgIcon]="step.icon"
      ></mat-icon>
      <mzima-client-button
        fill="outline"
        color="secondary"
        [iconOnly]="true"
        data-qa="btn-close"
        (buttonClick)="finishOnboarding()"
        class="onboarding-step__close-btn"
        ariaLabel="{{ 'modal.button.close' | translate }}"
      >
        <mat-icon icon svgIcon="close"></mat-icon>
      </mzima-client-button>
    </div>
    <div class="onboarding-step__content">
      <div
        [innerHTML]="step.content"
        *ngIf="i !== onboardingSteps.length - 1; else finalStepContent"
      ></div>
    </div>
    <div class="onboarding-step__footer">
      <mzima-client-button
        fill="outline"
        color="secondary"
        (buttonClick)="prevStep()"
        *ngIf="i > 0 && i !== onboardingSteps.length - 1"
      >
        {{ 'app.previous' | translate }}
      </mzima-client-button>
      <mzima-client-button (buttonClick)="nextStep()" [id]="'onboarding-button-' + step.icon">
        <ng-container *ngIf="i === 0 || i === onboardingSteps.length - 1; else nextStepText">
          {{ 'app.ok_sounds_good' | translate }}
        </ng-container>
        <ng-template #nextStepText>{{ 'app.next_step' | translate }}</ng-template>
      </mzima-client-button>
    </div>
  </tour-step>
</ng-container>

<ng-template #finalStepContent>
  <p>
    How exciting! Now you know the most important features and you’re ready to start using Ushahidi
    platform.
  </p>
  <p>
    Remember, in case of any question, don’t hesitate to reach out to our team by
    <a (click)="openSupportModal()">Help&Support</a>.
  </p>
</ng-template>
