<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<strong mat-dialog-title>{{ 'survey.choose_survey' | translate }}</strong>

<div mat-dialog-content>
  <app-spinner class="spinner" *ngIf="!types"></app-spinner>
  <ul role="list" class="types-list" *ngIf="!!types" [data-qa]="'add-post-modal-surveys'">
    <li *ngFor="let type of types">
      <div
        matRipple
        *ngIf="type.visible"
        role="listitem"
        [ngStyle]="{ '--color': type.color }"
        [mat-dialog-close]="{ type: type.id }"
        class="type-item"
        [data-qa]="'add-post-modal-surveys-item' + type.id"
      >
        {{ type.name }}
      </div>
    </li>
  </ul>
</div>
