<div class="cookies-notification" *ngIf="showCookies">
  <div class="cookies-notification__body">
    <div class="cookies-notification__body-icon">
      <mat-icon class="sidebar-menu-button__icon" [svgIcon]="'cookies'"></mat-icon>
    </div>
    <div class="cookies-notification__body-description">
      <p>{{ 'cookies.we_use_cookies' | translate }}</p>
      <p [innerHTML]="'cookies.cookies_privacy' | translate"></p>
    </div>
  </div>

  <div class="cookies-notification__buttons">
    <mzima-client-button
      fill="outline"
      type="button"
      color="secondary"
      (buttonClick)="decline()"
      [data-qa]="'button-decline-cookies'"
    >
      {{ 'cookies.decline' | translate }}
    </mzima-client-button>
    <mzima-client-button (buttonClick)="accept()" [data-qa]="'button-accept-cookies'">
      {{ 'cookies.accept_cookies' | translate }}
    </mzima-client-button>
  </div>
</div>
