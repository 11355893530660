<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<strong mat-dialog-title [data-qa]="'lang-modal-title'">{{
  (isDesktop$ | async) ? ('form.choose_language' | translate) : 'Choose language'
}}</strong>
<form class="search-form">
  <div class="form-row">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="{{ 'global_filter.search' | translate }}"
        (input)="searchLanguage($event)"
      />
      <mzima-client-button matPrefix fill="clear" color="secondary" [iconOnly]="true">
        <mat-icon icon svgIcon="search-small"></mat-icon>
      </mzima-client-button>
    </mat-form-field>
  </div>
</form>
<div mat-dialog-content>
  <ul role="list" class="types-list" [data-qa]="'survey-list'">
    <li role="listitem" class="type-item" *ngFor="let language of languages">
      <mat-checkbox
        [data-qa]="'lang-' + language.code"
        [checked]="data.activeLanguages.includes(language)"
        class="type-item__checkbox"
        (click)="$event.stopPropagation()"
        (change)="selectLanguage($event, language)"
        [disabled]="data.defaultLanguage?.name === language.name"
      >
        {{ language.name }}
      </mat-checkbox>
    </li>
  </ul>
</div>
<div mat-dialog-actions align="end">
  <mzima-client-button
    fill="outline"
    color="secondary"
    [mat-dialog-close]="false"
    [data-qa]="'btn-lang-cancel'"
  >
    {{ 'app.cancel' | translate }}
  </mzima-client-button>
  <mzima-client-button
    [mat-dialog-close]="data.activeLanguages"
    [disabled]="!data.activeLanguages.length"
    [data-qa]="'btn-lang-add'"
  >
    {{ 'app.add' | translate }}
  </mzima-client-button>
</div>
