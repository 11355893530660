<!-- Getting rounded & icon only's true or false using @Input instead fixes flickering issue on resize - Yay! -->
<mzima-client-button
  (buttonClick)="addPost()"
  class="submit-post-button"
  [rounded]="isRound"
  [iconOnly]="hasIconOnly"
  [data-qa]="'submit-post-button'"
>
  <span *ngIf="hasText">{{ 'app.add_new_post' | translate }}</span>
  <mat-icon icon svgIcon="plus"></mat-icon>
</mzima-client-button>
