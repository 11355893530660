<div class="snackbar">
  <mat-progress-bar
    class="snackbar__progress"
    mode="query"
    *ngIf="data.isLoading"
  ></mat-progress-bar>
  <span *ngIf="data.icon" class="snackbar__icon" [ngClass]="data.icon.color || 'primary'">
    <mat-icon [svgIcon]="data.icon.name"></mat-icon>
  </span>
  <div class="snackbar__main">
    <h3 class="snackbar__title" *ngIf="data.title">{{ data.title | translate }}</h3>
    <p *ngIf="data.message">{{ data.message | translate }}</p>

    <div class="snackbar__buttons" *ngIf="data.buttons?.length">
      <mzima-client-button
        class="button"
        *ngFor="let button of data.buttons"
        [color]="button.color ?? 'primary'"
        (buttonClick)="closeSnackbar(button.handler)"
      >
        {{ button.text! | translate }}
      </mzima-client-button>
    </div>
  </div>
</div>
