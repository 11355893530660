<div
  matRipple
  class="collection-item"
  (click)="selectable && changeChecked($event)"
  [ngClass]="{ 'collection-item--has-checkbox': selectable }"
  [attr.data-qa]="'collection-item'"
>
  <div *ngIf="selectable" class="collection-item__checkbox" (click)="$event.preventDefault()">
    <mat-checkbox [checked]="checked"> </mat-checkbox>
  </div>
  <div class="collection-item__actions" *ngIf="actions && !selectable">
    <mzima-client-button
      fill="clear"
      tabindex="-1"
      [iconOnly]="true"
      color="secondary"
      class="collection-item__control"
      (buttonClick)="editCollection($event)"
    >
      <mat-icon icon svgIcon="collection-edit"></mat-icon>
    </mzima-client-button>
    <mzima-client-button
      fill="clear"
      tabindex="-1"
      [iconOnly]="true"
      color="secondary"
      class="collection-item__control"
      (buttonClick)="deleteCollection($event)"
    >
      <mat-icon icon svgIcon="delete"></mat-icon>
    </mzima-client-button>
  </div>

  <strong class="collection-item__title">{{ collection.name }}</strong>
  <span class="collection-item__description">{{ collection.description }}</span>
</div>
