<div class="location-select">
  <h4 class="location-select__title" *ngIf="isDesktop$ | async">
    {{ 'global_filter.city_or_address' | translate }}
  </h4>

  <div class="form-row">
    <mat-label *ngIf="!(isDesktop$ | async)!">{{
      'global_filter.city_or_address' | translate
    }}</mat-label>
    <mat-form-field appearance="outline" class="form-control location-control">
      <input
        matInput
        [(ngModel)]="searchQuery"
        (ngModelChange)="search()"
        [matAutocomplete]="cities"
        [ngModelOptions]="{ standalone: true }"
        [placeholder]="'global_filter.location_placeholder' | translate"
      />
      <mzima-client-button
        matSuffix
        fill="clear"
        [iconOnly]="true"
        color="secondary"
        *ngIf="searchQuery"
        (buttonClick)="clear()"
        class="location-control__clear"
      >
        <mat-icon icon svgIcon="close"></mat-icon>
      </mzima-client-button>
      <mat-autocomplete
        autoActiveFirstOption
        autoSelectActiveOption
        #cities="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelected($event)"
      >
        <mat-option [value]="option" *ngFor="let option of citiesOptions | async">
          {{ option.display_name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-label *ngIf="!(isDesktop$ | async)!">{{
      'global_filter.viewing_mode' | translate
    }}</mat-label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Distance"
        disableOptionCentering
        [(ngModel)]="location_distance"
        (ngModelChange)="changeDistance()"
      >
        <mat-option [value]="option.value" *ngFor="let option of distanceOptions">
          {{ option.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
