<mat-radio-group class="radio-buttons" [value]="value.value" (change)="radioGroupChange($event)">
  <ng-container *ngFor="let item of data">
    <mat-radio-button
      [value]="item.value"
      [checked]="item.value === value.value"
      [disabled]="value.disabled!"
      [attr.data-qa]="item.name.split(' ').join('-')| lowercase"
    >
      <mat-icon *ngIf="isIconVisible && item?.icon">{{ item.icon }}</mat-icon>
      {{ item.name }}
    </mat-radio-button>
    <span
      class="radio-buttons__options"
      *ngIf="item?.options?.length! > 1 && value.value === 'specific'"
    >
      <mat-selection-list [(ngModel)]="value.options">
        <mat-list-option
          color="primary"
          class="list-option"
          [value]="option.value"
          checkboxPosition="before"
          [selected]="option.checked"
          [disabled]="option.disabled"
          [attr.data-qa]="option.name.split(' ').join('-')| lowercase"
          *ngFor="let option of item.options"
        >
          {{ option.name }}
        </mat-list-option>
      </mat-selection-list>
    </span>
  </ng-container>
</mat-radio-group>
