<form
  novalidate
  [formGroup]="resetForm"
  (ngSubmit)="restorePassword()"
  class="auth-form"
  autocomplete="new-password"
>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.token' | translate }}</mat-label>
      <input required autocomplete="new-password" matInput formControlName="token" type="text" />
      <mat-error *ngIf="resetForm.get('token')?.invalid">{{ getErrorMessage('token') }}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.new_password' | translate }}</mat-label>
      <input
        required
        matInput
        autocomplete="new-password"
        formControlName="password"
        [type]="isPasswordVisible ? 'text' : 'password'"
      />
      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        [disabled]="resetForm.disabled"
        (buttonClick)="togglePasswordVisible()"
      >
        <mat-icon icon [svgIcon]="isPasswordVisible ? 'eye-open' : 'eye'"></mat-icon>
      </mzima-client-button>
      <mat-error *ngIf="resetForm.get('password')?.invalid">{{
        getErrorMessage('password')
      }}</mat-error>
    </mat-form-field>
    <app-password-strength [passwordToCheck]="resetForm.value.password"></app-password-strength>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.confirm_password' | translate }}</mat-label>
      <input
        required
        matInput
        autocomplete="new-password"
        [errorStateMatcher]="matcher"
        formControlName="confirmPassword"
        placeholder="Enter new password"
        [type]="isPasswordVisible ? 'text' : 'password'"
      />

      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        [disabled]="resetForm.disabled"
        (buttonClick)="togglePasswordVisible()"
      >
        <mat-icon icon [svgIcon]="isPasswordVisible ? 'eye-open' : 'eye'"></mat-icon>
      </mzima-client-button>
      <mat-error *ngIf="resetForm.hasError('notSame')">{{
        'user.password_match' | translate
      }}</mat-error>
    </mat-form-field>
  </div>

  <mat-error>{{ formError }}</mat-error>

  <div mat-dialog-actions align="end">
    <ng-content select="[btn-cancel]"></ng-content>

    <mzima-client-button type="submit" [disabled]="resetForm.invalid || resetForm.disabled">
      {{ 'user.update_password' | translate }}
    </mzima-client-button>
  </div>
</form>
