<form novalidate [formGroup]="form" class="auth-form" (ngSubmit)="resetPassword()">
  <div class="form-row">
    <p>{{ 'user.forgot_instructions' | translate }}</p>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.email' | translate }}</mat-label>
      <input required matInput formControlName="email" />
      <mat-error *ngIf="form.get('email')?.invalid">{{ getErrorMessage('email') }}</mat-error>
    </mat-form-field>
  </div>

  <mat-error>{{ formError }}</mat-error>

  <div mat-dialog-actions align="end">
    <ng-content select="[btn-cancel]"></ng-content>

    <mzima-client-button type="submit" [disabled]="form.invalid || form.disabled">
      {{ 'nav.resetmypassword' | translate }}
    </mzima-client-button>
  </div>
</form>
