<div
  class="multilevelselect"
  [matMenuTriggerFor]="menu"
  #t="matMenuTrigger"
  [class.open]="t.menuOpen"
>
  <span class="multilevelselect__control">
    {{ value || placeholder }}
  </span>
  <mat-icon svgIcon="arrow-down" class="multilevelselect__arrow"></mat-icon>
</div>

<mat-menu class="multilevelselect__dropdown" #menu="matMenu">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="multilevelselect__dropdown"
  >
    <mat-selection-list [(ngModel)]="values" (selectionChange)="changed()">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <mat-list-option
          color="primary"
          [value]="node.id"
          class="list-option"
          checkboxPosition="before"
          (click)="$event.stopPropagation()"
        >
          {{ node.name }}
        </mat-list-option>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <mat-list-option
          color="primary"
          [value]="node.id"
          class="list-option"
          checkboxPosition="before"
          (click)="$event.stopPropagation()"
        >
          {{ node.name }}
        </mat-list-option>
        <mzima-client-button
          [iconOnly]="true"
          fill="clear"
          color="secondary"
          matTreeNodeToggle
          [ariaLabel]="'Toggle ' + node.name"
        >
          <mat-icon icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </mzima-client-button>
      </mat-tree-node>
    </mat-selection-list>
  </mat-tree>
</mat-menu>
