<div class="language">
  <mat-form-field>
    <mat-select
      disableOptionCentering
      [(value)]="selectedLanguage"
      (selectionChange)="changeLanguage($event)"
    >
      <mat-select-trigger class="language__selected">
        {{ selectedLanguage }}
        <mat-icon svgIcon="arrow-down" class="language__selected__arrow"></mat-icon>
      </mat-select-trigger>
      <mat-option *ngFor="let lang of languages" [value]="lang.code" class="language__option">
        <img
          [alt]="lang.code"
          class="language__option__flag"
          [src]="'assets/images/flags/' + (lang.code | lowercase) + '.svg'"
        />
        <span class="language__option__title">{{ lang.name }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
