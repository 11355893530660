<div class="sidebar" *ngIf="isDesktop || !isInnerPage">
  <app-deployment-details></app-deployment-details>
  <app-submit-post-button
    class="sidebar__add-post-btn"
    [isRound]="false"
    [hasIconOnly]="false"
    [hasText]="true"
    *ngIf="checkAllowedAccessToSite()"
  >
  </app-submit-post-button>

  <nav class="sidebar__controls">
    <app-menu-list-links></app-menu-list-links>
    <app-menu-list-non-links></app-menu-list-non-links>
  </nav>
</div>
