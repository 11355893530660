<mzima-client-button
  *ngIf="!isDisableClose"
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<ng-container *ngIf="isDisableClose">
  <div class="access-denied__info">
    <h2 class="access-denied__info-title">{{ 'nav.forbidden' | translate }}!</h2>
    <p>{{ 'app.forbidden-login' | translate }}</p>
    <p>
      {{ 'app.contact_with_admin' | translate
      }}<a href="mailto:{{ adminEmail }}">{{ adminEmail }}</a>
    </p>
  </div>

  <app-login-form (loggined)="successfully($event)"></app-login-form>
</ng-container>

<mat-tab-group
  *ngIf="!isDisableClose"
  class="tabs"
  dynamicHeight
  disableRipple
  disablePagination
  animationDuration="0ms"
  [ngClass]="{ 'tabs--single': !isSignupActive }"
>
  <mat-tab [label]="'nav.login' | translate">
    <app-login-form (loggined)="successfully($event)">
      <mzima-client-button
        btn-cancel
        fill="outline"
        color="secondary"
        (buttonClick)="cancel()"
        [data-qa]="'btn-cancel'"
        *ngIf="isDesktop"
      >
        {{ 'app.cancel' | translate }}
      </mzima-client-button>
    </app-login-form>
  </mat-tab>
  <mat-tab [label]="'nav.registration' | translate" *ngIf="isSignupActive">
    <app-registration-form (registered)="successfully($event)">
      <mzima-client-button
        btn-cancel
        fill="outline"
        color="secondary"
        (buttonClick)="cancel()"
        [data-qa]="'btn-cancel'"
        *ngIf="isDesktop"
      >
        {{ 'app.cancel' | translate }}
      </mzima-client-button>
    </app-registration-form>
  </mat-tab>
</mat-tab-group>
