<div class="access-denied">
  <div class="access-denied__container">
    <h2 class="access-denied__title">{{ 'nav.forbidden' | translate }}</h2>
    <p>{{ 'app.forbidden-login' | translate }}</p>
    <p>
      {{ 'app.contact_with_admin' | translate
      }}<a href="mailto:{{ adminEmail }}">{{ adminEmail }}</a>
    </p>
  </div>
</div>
