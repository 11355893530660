<div class="page-not-found">
  <div class="page-not-found__container">
    <p class="page-not-found__title" [data-qa]="'page-not-found-title'">
      <span class="page-not-found__title-404">{{ '404.404' | translate }}</span>
      {{ '404.error' | translate }}
    </p>
    <p
      class="page-not-found__description"
      [innerHTML]="'404.description' | translate"
      [data-qa]="'page-not-found-description'"
    ></p>
    <mzima-client-button [expand]="false" (buttonClick)="goToMap()" [data-qa]="'button-go-to-map'">
      {{ '404.button' | translate }}
    </mzima-client-button>
  </div>
</div>
