<mat-toolbar class="toolbar">
  <mzima-client-button
    fill="clear"
    color="secondary"
    [iconOnly]="true"
    (buttonClick)="back()"
    class="toolbar__back"
    *ngIf="!isDesktop && isInnerPage"
  >
    <mat-icon icon svgIcon="back"></mat-icon>
  </mzima-client-button>
  <h1 class="toolbar__page-title" [data-qa]="'page-title'" *ngIf="isDesktop || !showSearchForm">
    {{ pageTitle | translate }}
  </h1>
  <div class="toolbar__controls">
    <app-search-form class="search-form" *ngIf="showSearchForm"></app-search-form>
    <app-share-and-donate *ngIf="isDesktop"></app-share-and-donate>
    <app-language
      class="toolbar__language"
      [languages]="languages"
      [selectedLanguage]="selectedLanguage"
    ></app-language>
  </div>
  <app-account-and-logout class="account"></app-account-and-logout>
  <mzima-client-button
    fill="clear"
    class="burger"
    color="secondary"
    [iconOnly]="true"
    *ngIf="!isDesktop"
    (buttonClick)="toggleBurgerMenu()"
    [ariaLabel]="'nav.open_menu' | translate"
  >
    <mat-icon icon svgIcon="hamburger"></mat-icon>
  </mzima-client-button>
</mat-toolbar>

<app-mobile-menu></app-mobile-menu>
