<div class="images">
  <ng-container *ngIf="multiple">
    <div class="image" *ngFor="let src of imageSrc; let i = index">
      <div class="preview">
        <img [src]="src" [alt]="'app.file_uploader' | translate" />
      </div>
      <div class="buttons">
        <mzima-client-button
          color="danger"
          [data-qa]="'btn-update-logo'"
          (buttonClick)="deleteImage(i)"
        >
          {{ 'app.delete' | translate }}
        </mzima-client-button>
      </div>
    </div>
    <div class="image">
      <div mat-ripple class="preview preview--empty" (click)="chooseFile()">
        <mat-icon svgIcon="photo"></mat-icon>
        <input
          #input
          name="file"
          type="file"
          class="input"
          [required]="required"
          [data-qa]="'btn-delete-logo'"
          (change)="uploadFile($event)"
          accept="image/jpeg, image/png"
        />
      </div>
      <div class="buttons">
        <mzima-client-button fill="outline" color="secondary" (buttonClick)="chooseFile()">
          {{ 'app.choose_photo' | translate }}
        </mzima-client-button>
      </div>
    </div>
  </ng-container>
  <div class="image" *ngIf="!multiple">
    <div
      mat-ripple
      class="preview"
      (click)="chooseFile()"
      [ngClass]="{ 'preview--empty': !imageSrc }"
    >
      <img [src]="imageSrc" *ngIf="imageSrc?.length" [alt]="'app.preview_image' | translate" />
      <mat-icon svgIcon="photo" *ngIf="!imageSrc"></mat-icon>
      <input
        #input
        name="file"
        type="file"
        class="input"
        [required]="required"
        [data-qa]="'btn-delete-logo'"
        (change)="uploadFile($event)"
        accept="image/jpeg, image/png"
      />
    </div>
    <div class="buttons">
      <mzima-client-button fill="outline" color="secondary" (buttonClick)="chooseFile()">
        {{ 'app.choose_photo' | translate }}
      </mzima-client-button>
      <mzima-client-button
        color="danger"
        *ngIf="imageSrc?.length"
        [data-qa]="'btn-update-logo'"
        (buttonClick)="deleteImage()"
      >
        {{ 'app.delete' | translate }}
      </mzima-client-button>
    </div>
  </div>
</div>
